<main class="main-content theme-bo">

  <section class="section section-category-header">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/bo/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>

          <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek een </span>
            <span class="line-2 h1 t-lower">basisschool</span>
          </h1>
          <div class="school-filter type-a clearfix">
            <div class="c-search clearfix">
              <input type="text" class="input" name="postcode" [(ngModel)]="zipcode" placeholder="Zoek op postcode"> <a [routerLink]="'/categorie/po/map'" [queryParams]="zipcode ? {'zipcode': zipcode} : {}">zoeken</a>
            </div>
            <a [routerLink]="['/', 'categorie', 'po', 'map']" class="match-c-search">
              <span>Zoeken zonder postcode <i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-cta capacity-headline">
    <div class="st-inner">
      <div class="container">
        <div class="blocks has-single-block">
          <div class="single-block">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Bekijk hier de</span>
              <span class="line-2 h1 t-lower">aanmeldperioden</span>
            </h2>
            <a routerLink="/artikel/aanmelden-bij-een-school-met-leerlingplafond" class="button" title="Bekijk hier de aanmeldperioden">bekijken <i class="icon-right-small"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/bo/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg" style="background-image: url('/assets/img/photos/PO/PO_01.jpg');">
            <span class="overlay"></span>
            <img src="/assets/img/photos/PO/PO_01.jpg" alt="Alternatieve beschrijving"/>
          </figure>
        </div>

        <div class="col-left indent">
          <h2 class="h2">Naar de basisschool</h2>
          <div class="article-output">
            <p>Uw kind mag als het 4 jaar is naar de basisschool. Dat is een grote stap. Natuurlijk wilt u een school uitzoeken die goed past bij u en uw kind. Daarom is het belangrijk om op tijd naar scholen te gaan kijken. De Scholenwijzer geeft informatie over alle basisscholen in Den Haag. Dat maakt kiezen een stuk makkelijker.</p>
            <p>In Den Haag is er een aanmeldprocedure. Als uw kind bijna 3 is, ontvangt u van de gemeente Den Haag het <a href="/assets/files/20220127-OCW_A4_Aanmeldformulier_BasisschoolDenHaag_v1T.pdf" target="_blank">aanmeldformulier</a> voor de basisschool. Een uitgebreide toelichting op het aanmeldformulier vindt u <a href="/assets/attachments/toelichting-op-het-aanmeldformulier-voor-basisscholen-in-den-haag.pdf" target="_blank">hier</a>. De Scholenwijzer geeft informatie over hoe het aanmelden werkt en hoe het zit met <a href="/artikel/voorrangsregels-faq">voorrangsregels</a> en <a href="/artikel/hoe-werkt-loting">loten</a>.</p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-cta">
    <div class="st-inner">
      <div class="container">

        <div class="blocks category-blocks">
          <div class="block">
            <h2 class="h3 split-title">
              <span class="line-1 f1 mb-0">Mijn kind is 2 jaar, </span>
              <span class="line-2 h2 t-lower">Een basisschool kiezen</span>
            </h2>
            <a [routerLink]="['/', 'categorie', 'een-basisschool-kiezen']" class="button" title="Mijn kind is 2 jaar, ik wil mij oriënteren voor het basisonderwijs">
              Lees meer
              <i class="icon-right-small"></i>
            </a>
          </div>
          <div class="block">
            <h2 class="h3 split-title">
              <span class="line-1 f1 mb-0">Mijn kind is 3 jaar, </span>
              <span class="line-2 h2 t-lower">Aanmelden bij de basisschool</span>
            </h2>
            <a [routerLink]="['/', 'categorie', 'aanmelden-bij-de-basisschool']" class="button" title="Mijn kind is 3 jaar, ik wil mij aanmelden voor het basisonderwijs">
              Lees meer
              <i class="icon-right-small"></i>
            </a>
          </div>
          <div class="block">
            <h2 class="h3 split-title">
              <span class="line-1 f1 mb-0">Mijn kind is 4 jaar of ouder, </span>
              <span class="line-2 h2 t-lower">Aanmelden bij de basisschool</span>
            </h2>
            <a [routerLink]="['/', 'artikel', 'mijn-kind-is-4-jaar-of-ouder']" class="button" title="Mijn kind is 4 jaar of ouder, ik wil mij aanmelden voor het basisonderwijs">
              Lees meer
              <i class="icon-right-small"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="section section-category-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">basisonderwijs in Den Haag</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="PO"></app-sub-category-list>

      </div>
    </div>
  </section>


  <section id="faqs" class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over het</span>
            <span class="line-2 h1">basisonderwijs</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>
